





















import { Component, Vue } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
import isOS from '@/helper/detectOs'
import router from '@/router'

@Component({
  components: {
    PageLayout,
    ContentArea,
    PrimaryButton
  }
})
export default class SplashScreen extends Vue {
  get preferMobileHintVisible (): boolean {
    return !this.$device.mobile
  }

  private created (): void {
    const serialNumber = this.$route.query.serial_number as string
    const caseId = this.$route.query.case_id as string
    const redirectUrl = this.$route.query.redirect_url as string

    localStorage.setItem('serialNumber', serialNumber)
    localStorage.setItem('caseId', caseId)
    localStorage.setItem('redirectUrl', redirectUrl)
    localStorage.setItem('cartridge_verified', '')
    setTimeout(() => router.push({ name: 'welcome' }), 1000)
  }

  private mounted (): void {
    if (window.innerWidth < 767 && isOS()) window.scrollTo(0, 1)
  }
}
